import {
  auth, 
} from '../../@crema/services/auth/firebase/firebase';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../shared/constants/ActionTypes'; 

export const onSignOutFirebaseUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      auth
        .signOut()
        .then((data) => {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_AUTH_USER, payload: null});
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
}; 
