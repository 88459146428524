/************ 
 * Developed by : Shiva Software Solutions
 * Date : 29-07-2021
 * Descriptions : Toaster Message
 * Dependicies : To use @material-ui/core 
 ************/

import React from 'react'; 
import {makeStyles} from '@material-ui/core/styles'; 
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning'; 
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close'; 
import CheckCircleIcon from '@material-ui/icons/CheckCircle'; 
import {amber, green} from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import clsx from 'clsx';
const SnackbarMsg = ({snackbaropen, variantdata, msgdata,onDeny}) => { 
  
  function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const {className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];
    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  }
  const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  };
  const useStyles1 = makeStyles((theme) => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  })); 
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    onDeny(false); 
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbaropen}
        autoHideDuration={3000}
        onClose={handleClose} >
        <MySnackbarContentWrapper 
          variant={variantdata}
          message={msgdata}
          onClose={handleClose}
        />
      </Snackbar>
    </>
    
  );
};

export default SnackbarMsg;
SnackbarMsg.propTypes = { 
    snackbaropen: PropTypes.bool.isRequired, 
    variantdata: PropTypes.node.isRequired,
    msgdata: PropTypes.node.isRequired,
    onDeny: PropTypes.func.isRequired,
  };
