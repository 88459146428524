/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route page for signup
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./Signin/index')),
      },
    ],
  },

  {
    routes: [
      {
        path: '/signup',
        component: React.lazy(() => import('./Signup/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/set-password',
        component: React.lazy(() => import('./SetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/requestPassword',
        component: React.lazy(() => import('./RequestSetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-401',
        component: React.lazy(() => import('../errorPages/Error401/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/ExpiredLink',
        component: React.lazy(() => import('../errorPages/ExpiredLink')),
      },
    ],
  },
  //Terms and conditions page
  {
    routes: [
      {
        path: '/terms-conditions',
        component: React.lazy(() => import('./Signup/Termsandconditions')),
      },
    ],
  },
];
