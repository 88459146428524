/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : My profile api calling
 ************/
import {
  FETCH_ERROR,
  FETCH_START,
  DOCTOR_QUALIFICATION,
  STATE_MASTER,
  DOCTOR_SPECIALIZATION,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_PROFILE,
  SAVE_PROFILES,
  SAVE_PREFERENCES,
  OTP_SIGNUP_ERROR,
  OTP_UPDTE_SUCCESS,
  OTP_UPDTE_ERROR,
  OTP_ERROR,
  UPDATE_SETTINGS,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'myProfile.js';
export const onGetDoctorMaster = () => {
  return (dispatch) => {
    jwtAxios
      .post('v1/doctorMasters/mastersjwt')
      .then((data) => {
        try {
          var body = {jwtToken: data.data.body['token']};
          jwtAxios
            .post('v1/doctorMasters/masters', body)
            .then((data) => {
              dispatch({
                type: DOCTOR_QUALIFICATION,
                payload: data.data.body.quaList,
              });
              dispatch({
                type: STATE_MASTER,
                payload: data.data.body.stateList,
              });
              dispatch({
                type: DOCTOR_SPECIALIZATION,
                payload: data.data.body.specialization,
              });
              dispatch({
                type: DOCTOR_SUBSPECIALIZATION,
                payload: data.data.body.subspecialization,
              });
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDoctorMaster',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorMaster',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetDoctorMaster',
            '0',
            error,
          ),
        );
      });
  };
};

// update doctor api
export const updateDoctorProfile = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/createjwt', value)
        .then((data) => {
          try {
            if (
              data.data &&
              data.data.message &&
              data.data.message.includes('Error')
            ) {
              dispatch({
                type: OTP_UPDTE_ERROR,
                payload: data.data.message.split(':')[1],
              });
              return;
            }
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/doctorMasters/create', value)
              .then((data) => {
                dispatch({
                  type: SAVE_PROFILES,
                  payload: true,
                });
                dispatch({
                  type: OTP_UPDTE_SUCCESS,
                  payload: false,
                });
                dispatch({
                  type: OTP_ERROR,
                  payload: false,
                });
                window.location.reload();
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateDoctorProfile',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateDoctorProfile',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateDoctorProfile',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateDoctorProfile',
          '0',
          err,
        ),
      );
    }
  };
};

// update doctor api
export const updateDoctorPreferences = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/updatePreferencesjwt', value)
        .then((data) => {
          try {
            if (
              data.data &&
              data.data.message &&
              data.data.message.includes('Error')
            ) {
              dispatch({
                type: OTP_UPDTE_ERROR,
                payload: data.data.message.split(':')[1],
              });
              return;
            }
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/doctorMasters/updatePreferences', value)
              .then((data) => {
                dispatch({
                  type: SAVE_PREFERENCES,
                  payload: true,
                });
                dispatch({
                  type: OTP_UPDTE_SUCCESS,
                  payload: false,
                });
                dispatch({
                  type: OTP_ERROR,
                  payload: false,
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateDoctorProfile',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateDoctorProfile',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateDoctorProfile',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateDoctorProfile',
          '0',
          err,
        ),
      );
    }
  };
};
//get profile data
export const onGetDoctorData = (body) => {
  return (dispatch) => {
    jwtAxios
      .post('v1/doctorMasters/getDoctorDataJwt', body)
      .then((data) => {
        body['jwtToken'] = data.data.body['token'];
        jwtAxios
          .post('v1/doctorMasters/getDoctorData', body)
          .then((data) => {
            dispatch({
              type: DOCTOR_PROFILE,
              payload: data.data.body,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDoctorData',
                '0',
                error,
              ),
            );
          });
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetDoctorData',
            '0',
            error,
          ),
        );
      });
  };
};

export const onGetStatemaster = (body) => {
  return (dispatch) => {
    jwtAxios
      .post('v1/patMgmt/mastersjwt', body)
      .then((data) => {
        body['jwtToken'] = data.data.body['token'];
        jwtAxios
          .post('v1/patMgmt/masters', body)
          .then((data) => {
            dispatch({
              type: STATE_MASTER,
              payload: data.data.body.stateList,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetStatemaster',
                '0',
                error,
              ),
            );
          });
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetStatemaster',
            '0',
            error,
          ),
        );
      });
  };
};

// update doctor settings api
export const updateDoctorSettings = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/docMgmt/updateSettingsJwt', value)
        .then((data) => {
          try {
            if (
              data.data &&
              data.data.message &&
              data.data.message.includes('Error')
            ) {
              dispatch({
                type: OTP_SIGNUP_ERROR,
                payload: data.data.message.split(':')[1],
              });
              return;
            }
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/docMgmt/updateSettings', value)
              .then((data) => {
                if (data.status == 200) {
                  dispatch({
                    type: UPDATE_SETTINGS,
                    payload: true,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'UPDATE_SETTINGS',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'UPDATE_SETTINGS',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'UPDATE_SETTINGS',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'UPDATE_SETTINGS', '0', err),
      );
    }
  };
};
